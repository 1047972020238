import React from 'react'

const ROW_SPLIT_CHAR = '+'
const DEFAULT_CONTENT = [
  "Yes,No,I don't know,Something else",
  "Help,Thank you,Water,Food",
].join(ROW_SPLIT_CHAR).split(',').join('.').split(' ').join('_')

function encodeRow(text) {
  return text.split(',')
    .map(t => t && t.trim())
    .join('.')
    .split(' ').join('_')
}

function sayThis (text) {
  const synth = window.speechSynthesis
  const utterThis = new SpeechSynthesisUtterance(text)
  // const voices = synth.getVoices()
  // utterThis.voice = voices[0]
  synth.speak(utterThis)
}

function buttonClick (e) {
  const text = e.target.value
  sayThis(text)
}

function App() {
  const [ input, setInput ] = React.useState('')
  const hash = window.location.hash
  const [ rows, setRows ] = React.useState(hash.replace('#rows=', ''))
  
  // Set state and update URL hash.
  function setRowData(rows) {
    setRows(rows)
    window.location.hash = `#rows=${(rows)}`
  }
  
  // Set default content.
  if (!rows || rows.length === 0) {
    setRowData(DEFAULT_CONTENT)
  }
  
  // Submit handler to handle adding new values.
  function onSubmit (e) {
    e.preventDefault()
    const text = e.target[0].value
    const row = encodeRow(text)
    if (row && row !== '_') {
      setRowData(`${row}${ROW_SPLIT_CHAR}${rows}`)
      setInput('')
    }
  }

  // Ability to remove existing rows via UI.
  function removeRow (e, index) {
    e.preventDefault()
    if (!window.confirm('Are you sure you want to delete this row?')) {
      return
    }
    let rowArr = rows.split(ROW_SPLIT_CHAR)
    rowArr.splice(index, 1)
    const newRows = rowArr.join(ROW_SPLIT_CHAR)
    setRowData(newRows)
  }
  
  return (
    <div className="App">
      <header className="App-header pv3 mb2">
        <span className="dib v-top mb2 mb0-ns mr3-l mr2">
          <a className="white no-underline f6 f5-m f4-l" href="/">
            say4me.com
          </a>
        </span>
        <form className="dib w-100 w-80-ns pr2 pl2 pa0-ns" onSubmit={(e) => onSubmit(e)}>
          <div className="flex w-100">
            <input
              placeholder="Enter options"
              className="flex-auto"
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <button className="no-select">
              Submit
            </button>
          </div>
          <div className="tl f6 o-70 pv1">
            Enter options above, separated by commas (',') for different options or a plus ('+') for new rows.
          </div>
        </form>
      </header>
      <div className="w-100">
        {rows && rows.split(ROW_SPLIT_CHAR).map((row, index) => {
          return (
            <div className="row mb2 w-100 flex flex-row items-stretch h4" key={index}>
              {row.split('.').map((itm, ii) => {
                const item = itm.trim().split('_').join(' ')
                return (
                  <button
                    className="flat-button no-select ba bg-white h4 flex-auto fw5"
                    key={`${index}-${ii}`}
                    onClick={buttonClick}
                    value={item}>
                    {item}
                  </button>
                )
              })}
              <div>
                <button
                  type="button"
                  className="f6 v-top ml1 bg-black white round ba b--gray h2 w1 pa0 lh-solid"
                  onClick={(e) => removeRow(e, index)}>
                  x
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default App
